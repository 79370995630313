import React, { useState, forwardRef, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import ReactPixel from 'react-facebook-pixel';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { setGameCategories, setActiveTab } from '../../redux/slices/game';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from './header/withdraw/WithdrawFormDialog';
import PopupBanner from './PopupBanner';
import SubHeaderNav from '../../views/game/SubHeaderNav';
import { openLoginSignup } from '../../redux/slices/authPages';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ showDownloadApp, isDesktop, collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: '74px',
  background: 'none',
  backgroundImage: `url(${require('../../assets/bg1.webp')})`,
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  zIndex: 0,
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgb(72,69,106, .01) 15.6%, #201E36 100%)',
    opacity: 0.7,
    zIndex: -1,
  },
  [theme.breakpoints.only('xs')]: {
    paddingTop: showDownloadApp && !isDesktop ? HEADER.MOBILE_HEIGHT + 36 : HEADER.MOBILE_HEIGHT,
  },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
  [theme.breakpoints.up('sm')]: {
    backgroundPosition: '10% 78%',
    backgroundSize: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: '10% 10%',
    backgroundSize: '100% 100%',
  },
}));

const BottomNav = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    marginLeft: `calc(${NAVBAR.DASHBOARD_WIDTH}px)`,
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const [showDownloadApp, setShowDownloadApp] = useState(true);

  const verticalLayout = themeLayout === 'vertical';

  const location = useLocation();

  useEffect(() => {
    // Check if the PWA is already installed (usually stored in localStorage or can be checked with appinstalled event)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setShowDownloadApp(false);
    }

    // Listen for the `appinstalled` event
    window.addEventListener('appinstalled', () => {
      setShowDownloadApp(false);
    });

    return () => {
      window.removeEventListener('appinstalled', () => {
        setShowDownloadApp(false);
      });
    };
  }, []);

  useEffect(() => {
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];

    if (isAuthenticated) promises.push(getUserMemberBalance());
  }, [isAuthenticated]);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      // Comment out respond.io code as per Mike
      // if (siteSettings?.config_livechat) {
      //   const respondIo = JSON.parse(siteSettings?.config_livechat);
      //   const script = document.createElement('script');

      //   script.id = respondIo?.id;
      //   script.src = respondIo?.src;
      //   script.async = true;

      //   document.head.appendChild(script);
      // }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }
    } catch (error) {
      console.warn(`3RD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
          showDownloadApp={showDownloadApp}
          setShowDownloadApp={setShowDownloadApp}
        />

        {/* {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )} */}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
        showDownloadApp={showDownloadApp}
        setShowDownloadApp={setShowDownloadApp}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick} showDownloadApp={showDownloadApp} isDesktop={isDesktop}>
        <SubHeaderNav />
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
        <Footer />
        {/* BOTTOM NAVIGATION */}
        <BottomNav
          sx={{
            width: '100%',
            height: { xs: '70px', md: '90px' },
            // backgroundColor: palette.bottomMenu.bgColor,
            position: 'fixed',
            bottom: 0,
            left: 2,
            zIndex: 100,
            display: 'block',
            p: { xs: '4px 4px 0 4px', md: '20px 20px 0 20px' },
            right: 2,
          }}
        >
          <Grid
            container
            sx={{
              backgroundColor: palette.bottomMenu.bgColor,
              boxShadow: '0px 0px 8px 0px #FFD633',
              borderRadius: '500px',
              height: '60px',
            }}
          >
            <Grid
              onClick={() => setOpen(true)}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Image
                src={require('../../assets/menu/menu-white.png')}
                alt="Menu"
                sx={{
                  width: '22px',
                  height: '18px',
                  filter:
                    'brightness(0.7) saturate(150%) invert(30%) sepia(20%) saturate(200000%) hue-rotate(230deg) brightness(90%) contrast(120%)',
                }}
              />
              <Typography
                mt={0.5}
                sx={{
                  fontSize: '11px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                }}
              >
                {translate('menu')}
              </Typography>
            </Grid>

            <Grid
              onClick={() => navigate(`/games/slots`)}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Image
                src={require('../../assets/menu/nav-slot-white.png')}
                alt="Slot"
                sx={{
                  width: '30px',
                  height: '20px',
                  filter:
                    'brightness(0.7) saturate(150%) invert(30%) sepia(20%) saturate(200000%) hue-rotate(230deg) brightness(90%) contrast(120%)',
                }}
              />
              <Typography
                mt={0.5}
                sx={{
                  fontSize: '11px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                }}
              >
                {translate('slots')}
              </Typography>
            </Grid>
            {/* DEPOSIT */}
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Box
                onClick={() => {
                  if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                  else navigate('user/wallet');
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '68px',
                  // marginTop: '-20px',
                  width: '68px',
                  borderRadius: '100%',
                  cursor: 'pointer',
                }}
              >
                <Image
                  src={require('../../assets/home/deposit.png')}
                  alt="Deposit"
                  sx={{ height: '50px', width: '52px', objectFit: 'contain', mt: -3 }}
                />
                <Typography
                  sx={{ fontFamily: 'sans-serif', fontSize: '12px', fontWeight: 700, color: palette.bottomMenu.color }}
                  className="deposit-text-animation"
                >
                  {translate('deposit')}
                </Typography>
              </Box>
            </Grid>
            {/* LIVE CASINO */}
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
              onClick={() => navigate('/games/live-casino')}
            >
              <Image
                src={require('../../assets/menu/nav-live-white.png')}
                alt="Live Casino"
                sx={{
                  width: '20px',
                  height: '20px',
                  filter:
                    'brightness(0.7) saturate(150%) invert(30%) sepia(20%) saturate(200000%) hue-rotate(230deg) brightness(90%) contrast(120%)',
                }}
              />
              <Typography
                mt={0.5}
                sx={{
                  fontSize: '11px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                }}
              >
                {translate('live_casino')}
              </Typography>
            </Grid>
            {/* VIP CLUB */}
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                flex: 1,
              }}
              onClick={() => {
                navigate('/vip');
              }}
            >
              <Image
                src={require('../../assets/menu/nav-vip-white.png')}
                alt="Vip Club"
                sx={{
                  width: '24px',
                  height: '24px',
                  filter:
                    'brightness(0.7) saturate(150%) invert(30%) sepia(20%) saturate(200000%) hue-rotate(230deg) brightness(90%) contrast(120%)',
                }}
              />
              <Typography
                mt={0.5}
                sx={{
                  fontSize: '11px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                }}
              >
                {translate('vip_club')}
              </Typography>
            </Grid>
          </Grid>
        </BottomNav>
      </MainStyle>

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer && data.state.visibility === 'minimized') {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
