import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, IconButton, InputBase, Modal, Stack, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';
import { InputContainer, StyledInput, ThemeButton } from '../../../GlobalStyles';

const FormContainer = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: '4px',
  maxWidth: '900px',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: '10px',
  background: 'none',
}));

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ResetPasswordDialog = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const location = useLocation();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    newPassword: {
      value: '',
      show: false,
    },
    confirmPassword: {
      value: '',
      show: false,
    },
  });

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (location?.pathname === '/auth/reset-password' && !location?.state?.resetToken) {
      navigate('/home');
      return;
    }
    if (location?.state?.resetToken) setOpenResetPasswordDialog(true);
  }, [location?.pathname, location?.state]);

  const handleFormChange = (e, showName = null) => {
    if (showName) setFormData((prev) => ({ ...prev, [showName]: { show: !prev[showName]?.show } }));
    else setFormData((prev) => ({ ...prev, [e.target?.name]: { value: e.target?.value } }));
  };

  const onSubmit = (e) => {
    e?.preventDefault();

    if (formData?.newPassword?.value.trim().length < 6) {
      enqueueSnackbar(translate('password_atleast_6_characters'), snackbarOptionsError);
      return;
    }

    if (formData?.confirmPassword?.value !== formData?.newPassword?.value) {
      enqueueSnackbar(translate('password_doesnt_match'), snackbarOptionsError);
      return;
    }

    setIsSubmitting(true);

    conObj
      .post(`password_reset_otp.json`, {
        'user-new_password': formData?.newPassword?.value?.trim(),
        idToken: location?.state?.resetToken,
      })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            enqueueSnackbar(translate('password_reset_success'), snackbarOptionsSuccess);
            navigate('/');
            // setOpenLoginSignup({ open: true, isLogin: true });
          }
        }
      })
      .catch((err) => {
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .finally(() => setIsSubmitting(false));
  };

  const onCloseDialog = () => {
    setOpenResetPasswordDialog(false);
    navigate('/home');
  }

  return (
    <Modal
      open={openResetPasswordDialog}
      onClose={onCloseDialog}
      sx={{ overflow: 'hidden' }}
    >
      <Box
        sx={{
          backgroundColor: '#161f2c',
          borderRadius: '20px',
          height: 'auto',
          width: '96vw',
          maxWidth: '500px',
          overflow: 'auto',
          p: '50px 24px 24px',
          m: 'auto',
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          position: 'relative',
          marginTop: '50px',
        }}
        className="modal-container container-no-scrollbar"
      >
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={onCloseDialog}
        />

        <Typography sx={{ fontWeight: 600, color: 'white', fontSize: '18px', mb: 2 }}>
          {translate('reset_password')}
        </Typography>
        <FormContainer component={'form'} onSubmit={onSubmit}>
          <Stack spacing={2}>
            <Box>
              <Box sx={{ mb: 2 }}>
                <InputContainer direction={'row'}>
                  <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#55657e' }} />
                  <StyledInput
                    type={formData?.newPassword?.show ? 'text' : 'password'}
                    name="newPassword"
                    value={formData?.newPassword?.value}
                    placeholder={translate('new_password')}
                    onChange={handleFormChange}
                  />
                  <IconButton size="small" onClick={(e) => handleFormChange(e, 'newPassword')}>
                    <Iconify
                      icon={formData?.newPassword?.show ? 'ph:eye-light' : 'ph:eye-closed-light'}
                      sx={{ cursor: 'pointer' }}
                    />
                  </IconButton>
                </InputContainer>
              </Box>
              <Box sx={{ mb: 2 }}>
                <InputContainer direction={'row'}>
                  <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#55657e' }} />
                  <StyledInput
                    type={formData?.confirmPassword?.show ? 'text' : 'password'}
                    name="confirmPassword"
                    value={formData?.confirmPassword?.value}
                    placeholder={translate('confirm_password')}
                    onChange={handleFormChange}
                  />
                  <IconButton size="small" onClick={(e) => handleFormChange(e, 'confirmPassword')}>
                    <Iconify
                      icon={formData?.confirmPassword?.show ? 'ph:eye-light' : 'ph:eye-closed-light'}
                      sx={{ cursor: 'pointer' }}
                    />
                  </IconButton>
                </InputContainer>
              </Box>

              <ThemeButton type="submit" fullWidth loading={isSubmitting}>
                {translate('confirm')}
              </ThemeButton>
            </Box>
          </Stack>
        </FormContainer>
      </Box>
    </Modal>
  );
};

ResetPasswordDialog.propTypes = {};

export default ResetPasswordDialog;
